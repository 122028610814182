import "./Footer.css";

function Footer() {
  return (
    <div className="Footer">
      <h1 className="FooterText">
        © 2024 Mango Industries, Inc. All Rights Reserved.
      </h1>
    </div>
  );
}

export default Footer;
