function importAll(r) {
  let tokens = {};
  r.keys().forEach((item) => {
    tokens[item.replace("./", "")] = r(item);
  });
  return tokens;
}

const tokens = importAll(
  require.context("../assets/logos/tokens/", false, /\.svg$/)
);

export default tokens;
