import React from "react";
import "./Home.css";
import textLogoLinear from "../../../assets/logos/optionbase/textLogoBlueAndGreen.svg";

/* import token animation component (work in progress) */
import TokenAnimation from "../../../components/token-animation/TokenAnimation.js";

function Home() {
  return (
    <div className="HomePage">
      {/* add logo page header */}
      <div className="LogoAndHeader">
        <img src={textLogoLinear} alt="logo" className="TextLogo" />
        {/* header */}
        <h2 className="HomePageSubHeader">
          The only exchange where you can trade options and spot on any crypto
          asset.
        </h2>
      </div>
    </div>
  );
}

export default Home;
