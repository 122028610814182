import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState } from "react";

/* import main pages */
import HomePage from "./pages/main/home/Home.js";

/* import other pages */
import Error404Page from "./pages/errors/Error404.js";

/* import universal components */
import Header from "./components/header/Header.js";
import Footer from "./components/footer/Footer.js";
import DropDownMenu from "./components/drop-down-menu/DropDownMenu.js";

function App() {
  // state for drop down menu
  const [dropDown, setDropDown] = useState(false);

  // toggle drop down state
  const toggleDropDown = () => {
    // toggle drop down menu state
    setDropDown(!dropDown);
  };

  return (
    <div className="App">
      {/* universal header, dropdown, and footer */}
      {/* <Header toggleDropDown={toggleDropDown} /> */}
      {/* render drop down menu on toggle */}
      {/* {dropDown && <DropDownMenu toggleDropDown={toggleDropDown} />} */}

      <Footer />

      {/* routes */}
      <BrowserRouter>
        <Routes>
          {/* Unknown route (404 error) - (00) */}
          <Route path="*" element={<Error404Page />} />

          {/* home - (01) */}
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />

          {/* brand - (02) */}

          {/* careers - (03) */}

          {/* disclaimer - (04) */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
