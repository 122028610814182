import "./Error404.css";

/* import text logo and footer */
import Footer from "../../components/footer/Footer.js";
import Logo from "../../assets/logos/optionbase/textLogoBlue.svg";

function Error404() {
  return (
    <div className="Error404Container">
      {/* error page content */}

      <div className="ErrorMessage">
        {/* logo top of page - (right) */}
        <a href="/" className="LogoContainer">
          <img src={Logo} className="Logo" />
        </a>
        {/* error message heading */}
        <h1 className="ErrorHeader">
          We can't seem to find the page you're looking for...
        </h1>
        {/* error code*/}
        <h1 className="ErrorCode">Error code: 404</h1>

        {/* nav home to optionbase.org */}
        <button
          className="RedirectButton"
          onClick={() => (window.location.href = "https://optionbase.org")}
        >
          Home
        </button>

        {/* navigate to home page, app.optionbase.org */}

        <button
          className="RedirectButton"
          onClick={() => (window.location.href = "https://app.optionbase.org")}
        >
          Trade
        </button>

        {/* navigate to documentation page, docs.optionbase.org */}

        <button
          className="RedirectButton"
          onClick={() => (window.location.href = "https://docs.optionbase.org")}
        >
          Documentation
        </button>

        {/* navigate to forum, forum.optionbase.org */}

        <button
          className="RedirectButton"
          onClick={() =>
            (window.location.href = "https://forum.optionbase.org")
          }
        >
          Forum
        </button>
      </div>

      <Footer />
    </div>
  );
}
export default Error404;
